<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <span class="brand-logo">
            <b-img :src="appLogoImage" width="180px" alt="logo" />
          </span>
          <!-- <h2 class="brand-text text-primary ml-1">
          Secusy
        </h2> -->
        </b-link>

        <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
        <b-card-text class="mb-2"> Please provide your email address registered with us </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group label="Email" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="email"
                  />

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            

            <!-- submit button -->
            <b-button block type="submit" variant="primary">
              Send Link
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      email: "",
      // validation
      required,
    };
  },

  methods: {

    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = {
            email: this.email,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "password_reset/",
          };
          var self = this;
          this.$http(options).then((res) => {
            // if (res.data.id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Thank you. A link to reset your password has been send to you to this email address. Please check your email for more details.",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              // self.$router.push({name: 'login'});
            // }
          })
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Form Submitted',
          //       icon: 'EditIcon',
          //       variant: 'success',
          //     },
          //   })
        }
      });
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
